import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";

function ClickableAvatar({ user, onLogOut }) {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <>
          <IconButton {...bindTrigger(popupState)}>
            <Avatar src={user?.photoURL} alt={user?.displayName}></Avatar>
          </IconButton>
          <Menu {...bindMenu(popupState)}>
            <MenuItem
              onClick={() => {
                popupState.close();
                onLogOut();
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </>
      )}
    </PopupState>
  );
}

export default ClickableAvatar;
