import React, { useEffect, useState } from "react";
import Container from "./components/Container/Container";
import EpisodeList from "./components/EpisodeList/EpisodeList";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import ContentWrapper from "./components/ContentWrapper/ContentWrapper";
import ClickableAvatar from "./components/ClickableAvatar/ClickableAvatar";
import LoginButton from "./components/LoginButton/LoginButton";
import PodcastList from "./components/PodcastList/PodcastList";
import { defaultData } from "./defaultData";
import { signInWithPopup, signOut } from "firebase/auth";
import { googleAuthProvider, auth } from "./utils/firebase";
import Center from "./components/Center/Center";
import CircularProgress from "@mui/material/CircularProgress";

const signInWithGoogle = async () => {
  try {
    return signInWithPopup(auth, googleAuthProvider);
  } catch (e) {
    if (
      !["auth/cancelled-popup-request", "auth/popup-closed-by-user"].includes(
        e.code
      )
    )
      console.log(e);
  }
};

function App() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
      setIsLoading(false);
    });
  }, []);

  const login = async () => {
    await signInWithGoogle();
  };

  const logout = async () => {
    await signOut(auth);
  };

  return (
    <div className="App">
      <Header>
        {user ? (
          <ClickableAvatar user={user} onLogOut={logout} />
        ) : (
          <LoginButton onClick={login} />
        )}
      </Header>
      <ContentWrapper>
        <Container>
          {user ? (
            <PodcastList user={user} />
          ) : isLoading ? (
            <Center>
              <CircularProgress color="inherit" />
            </Center>
          ) : (
            <EpisodeList episodes={defaultData.searchForTerm.podcastEpisodes} />
          )}
        </Container>
      </ContentWrapper>
      <Footer />
    </div>
  );
}

export default App;
