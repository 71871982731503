import { Layout } from "antd";

function Footer() {
  return (
    <Layout.Footer
      style={{
        textAlign: "center",
        backgroundColor: "#EDE8D8",
        padding: "20px 0px",
      }}
    >
      Castel ©2023 Created by Emma Weberyd
    </Layout.Footer>
  );
}

export default Footer;
