export const defaultData = {
  searchForTerm: {
    podcastEpisodes: [
      {
        uuid: "8387f917-950a-4e27-bd6e-2b487b3c1284",
        name: "#16 –Andevärlden anropar Tommy!",
        description:
          "Andevärlden anropar Tommy! – med en pappa som också heter Tommy.Emma Knyckare letar efter en oerhört exakt procentsiffra på krograggande kvinnor (Obs, ej Palace inräknat). Ina har hittat en samhällsomstörtande Flashbackguerilla beväpnade med tygpåsar och Scroll-Mia har dykt ner bland den ljuva Skellefteewas välsignleser och förbannelser. Avsnitttet sponsras av våra Patreons, som dessutom snart kommer få möjligheten att köpa vår första merch någonsin via https://www.patreon.com/FlashbackForever Get bonus content on Patreon Hosted on Acast. See acast.com/privacy for more information.",
        audioUrl:
          "https://sphinx.acast.com/p/open/s/60dc19b21f5e91001249f5e5/e/https%3A%2F%2Fpinecast.com%2Fguid%2F36604ad2-ad21-4bdb-9edf-783fbb6b1b3d/media.mp3?tk=eyJ0ayI6ImRlZmF1bHQiLCJhZHMiOnRydWUsInNwb25zIjp0cnVlLCJzdGF0dXMiOiJwdWJsaWMifQ==&sig=56lnkO2mkSZHmsU-YruFA5sflEkae_8dd9cy1a6OqlA",
        imageUrl: null,
        podcastSeries: {
          uuid: "79d84a17-7d8d-44db-89db-6b5ac8e425a1",
          name: "Flashback Forever",
          imageUrl:
            "https://assets.pippa.io/shows/60dc19b21f5e91001249f5e5/1631265565548-3feec9b7624c7e68058d150c5e596620.jpeg",
        },
      },
      {
        uuid: "716c354b-8459-48e4-9c32-083c452b093e",
        name: "#17 –Bögrankor och bärande betong",
        description:
          "Bögrankor och bärande betongEmma undersöker teser om bögrankor och homoparadiset Jamaica, Ina försöker leta rätt på den ultimata knullpastan och Scroll-Mia har hittat en kofotsbeväpnad hobbyhantverkare med ett starkt märklarhat och ett enormt självförtroende. Avsnitttet sponsras av våra Patreons, som dessutom snart kommer få möjligheten att köpa vår första merch någonsin via https://www.patreon.com/FlashbackForever Get bonus content on Patreon Hosted on Acast. See acast.com/privacy for more information.",
        audioUrl:
          "https://sphinx.acast.com/p/open/s/60dc19b21f5e91001249f5e5/e/https%3A%2F%2Fpinecast.com%2Fguid%2F98daa638-b2c2-441a-9c82-423b61f0048a/media.mp3?tk=eyJ0ayI6ImRlZmF1bHQiLCJhZHMiOnRydWUsInNwb25zIjp0cnVlLCJzdGF0dXMiOiJwdWJsaWMifQ==&sig=AdE9ZepfyA4AAP2Jh_H2RvGHQhE5yTy0J7vO9HUIXdI",
        imageUrl: null,
        podcastSeries: {
          uuid: "79d84a17-7d8d-44db-89db-6b5ac8e425a1",
          name: "Flashback Forever",
          imageUrl:
            "https://assets.pippa.io/shows/60dc19b21f5e91001249f5e5/1631265565548-3feec9b7624c7e68058d150c5e596620.jpeg",
        },
      },
      {
        uuid: "8404ed79-9417-471f-bbea-799fcc91b1bc",
        name: "#18 –Hjälp! Jag är kär i min handläggare!",
        description:
          "Hjälp! Jag är kär i min handläggare!Ett oerhört fullmatat avsnitt av Flashback Forever har landat! Men lugn bara lugn, man får pausa i mitten och hämta andan. Emma Knyckare följer ett romantiskt drama som involverar en ung gamer, en Milf på arbetsförmedlingen, en helvetes massa smileys och stora delar av Flashback Forum. Ina har tagit reda på vad som händer där ute i stugorna när Alterego-Lulz, Rövroligheter och sexskoj får härja fritt. Och Scroll-Mia avslöjar konspirationsteorin bakom världens största hund och Flashbackaren som gett sig fan på att avslöja oegentligheterna i Guinness rekordbok. Get bonus content on Patreon Hosted on Acast. See acast.com/privacy for more information.",
        audioUrl:
          "https://sphinx.acast.com/p/open/s/60dc19b21f5e91001249f5e5/e/https%3A%2F%2Fpinecast.com%2Fguid%2F0838c967-fdc1-45c3-9a53-8f086ef37c8a/media.mp3?tk=eyJ0ayI6ImRlZmF1bHQiLCJhZHMiOnRydWUsInNwb25zIjp0cnVlLCJzdGF0dXMiOiJwdWJsaWMifQ==&sig=sOaZoyrG0owE8JHUI0_JIfi73SguIpmS1ZR_DW1tqXU",
        imageUrl: null,
        podcastSeries: {
          uuid: "79d84a17-7d8d-44db-89db-6b5ac8e425a1",
          name: "Flashback Forever",
          imageUrl:
            "https://assets.pippa.io/shows/60dc19b21f5e91001249f5e5/1631265565548-3feec9b7624c7e68058d150c5e596620.jpeg",
        },
      },
      {
        uuid: "a53755ca-a468-4428-8bb0-eab84dba92dd",
        name: "#38 – Charlie Parker var otroligt White trash.",
        description:
          "Charlie Parker var otroligt White trash.Emma har sökt bland Flashbacks Batman-trådar och hittat drogbekämpning, övergivna gubbar och undergivna Robinpojkar samt Flashbackmannen.Ina ligger nerbäddad och är med på länk men försöker göra sitt sorgliga febertillstånd lite mer spännande genom att dyka ner i Flashbacks Coronateorier om 5G, judar och Trumps Covfefe-tweet från 2017. Och Scroll-Mia har hittat ett gäng extremt spridda teorier om ifall det är vissling, Ferraribilar eller jazzmusiker som är mest White Trash. Get bonus content on Patreon Hosted on Acast. See acast.com/privacy for more information.",
        audioUrl:
          "https://sphinx.acast.com/p/open/s/60dc19b21f5e91001249f5e5/e/https%3A%2F%2Fpinecast.com%2Fguid%2F21e9ed7f-b55e-4209-8adf-dd8d8af2341f/media.mp3?tk=eyJ0ayI6ImRlZmF1bHQiLCJhZHMiOnRydWUsInNwb25zIjp0cnVlLCJzdGF0dXMiOiJwdWJsaWMifQ==&sig=PCu--0R_Mx2R5qi8QDTCfmFNHaY__i6tebTPOZDky08",
        imageUrl: null,
        podcastSeries: {
          uuid: "79d84a17-7d8d-44db-89db-6b5ac8e425a1",
          name: "Flashback Forever",
          imageUrl:
            "https://assets.pippa.io/shows/60dc19b21f5e91001249f5e5/1631265565548-3feec9b7624c7e68058d150c5e596620.jpeg",
        },
      },
      {
        uuid: "7d6c8a98-84d5-43b9-a469-ea32bf6aee1a",
        name: "#45 –Brinner du också för grovfoder?",
        description:
          "Brinner du också för grovfoder?Äntligen har vi äntrat flashback och poddstudion igen! I veckans avsnitt tar Ina reda på vad Flashbackarna med sorgsna ögon och spelad glädje har hittat för undermåliga klappar under granen. Scroll-Mia förklarar krig mot den moderna platsannonsen men hittar även ett lite väl våldsbejakande CV. Och Emma låter sig inspireras av Flashbackarnas nyårfir, hittar en gammal favorits pyrotekniska party och brister ut i skönsång. Ja ni hör ju: Vi är tillbaka efter ledigheten och allt är precis som vanligt.Avsnittet sponsras av våra fantastiska Patreons på https://www.patreon.com/FlashbackForeverInas trådar: https://www.flashback.org/t1750196 https://www.flashback.org/t3292094 https://www.flashback.org/t1398320 https://www.flashback.org/t1075795Mias trådar: https://www.flashback.org/t1993023 https://www.flashback.org/t3248495Emmas trådar: https://www.flashback.org/t3106444 https://www.flashback.org/sok/Vi%20som%20skiter%20i%20ny%C3%A5r Get bonus content on Patreon Hosted on Acast. See acast.com/privacy for more information.",
        audioUrl:
          "https://sphinx.acast.com/p/open/s/60dc19b21f5e91001249f5e5/e/https%3A%2F%2Fpinecast.com%2Fguid%2Fe8d63859-72b9-4f12-a615-0a2721a7a53f/media.mp3?tk=eyJ0ayI6ImRlZmF1bHQiLCJhZHMiOnRydWUsInNwb25zIjp0cnVlLCJzdGF0dXMiOiJwdWJsaWMifQ==&sig=acaSFavaDGKw9NeYlxPzj1LlG_O2yNveWyNFyphgROs",
        imageUrl: null,
        podcastSeries: {
          uuid: "79d84a17-7d8d-44db-89db-6b5ac8e425a1",
          name: "Flashback Forever",
          imageUrl:
            "https://assets.pippa.io/shows/60dc19b21f5e91001249f5e5/1631265565548-3feec9b7624c7e68058d150c5e596620.jpeg",
        },
      },
      {
        uuid: "2b75acbc-1265-486c-8464-a1b7f1bc73c0",
        name: "Lucka 6: Vad blir det för stånd?",
        description:
          "Lucka 6: Vad blir det för stånd?Erektionen på bilden kanske är den mest avhandlade detaljen av alla detaljer man kan avhandla i böglyftstråden. Men ändå kvarstår frågan: Var är det för stånd? Ty det finns ju så många att välja mellan. Professorn i urologi tillika sångaren i Snowstorm, Ralph Peeker, hjälpte oss reda ut vad för slags stånd det egentligen är frågan om. Tack till: Ralph PeekerAvsnittsmusik: Snowstorm - SommarnattMusikläggning och mastring: Jakob Bergman, Signatur och jingel: Martin PermerLänk till extramaterial: www.flashbackforever.se/julkalender2020 (använd användarnamn och lösenord du fick i mailbekräftelsen från storfittekajsa) Get bonus content on Patreon Hosted on Acast. See acast.com/privacy for more information.",
        audioUrl:
          "https://sphinx.acast.com/p/open/s/60dc19b21f5e91001249f5e5/e/https%3A%2F%2Fpinecast.com%2Fguid%2Fa4a8d2e3-136d-4939-a407-7fb99c50b4dc/media.mp3?tk=eyJ0ayI6ImRlZmF1bHQiLCJhZHMiOnRydWUsInNwb25zIjp0cnVlLCJzdGF0dXMiOiJwdWJsaWMifQ==&sig=3hGJ_552Mgivh2eZnbHIkx7r6WwylnYOCpWWMdb7e9o",
        imageUrl:
          "https://assets.pippa.io/shows/60dc19b21f5e91001249f5e5/60dc19bc1c4f4600136eab2f.jpg",
        podcastSeries: {
          uuid: "79d84a17-7d8d-44db-89db-6b5ac8e425a1",
          name: "Flashback Forever",
          imageUrl:
            "https://assets.pippa.io/shows/60dc19b21f5e91001249f5e5/1631265565548-3feec9b7624c7e68058d150c5e596620.jpeg",
        },
      },
      {
        uuid: "4f09111d-e4a5-48aa-8e4f-798fefa4bd01",
        name: "#96 Best of the Very Very Best, vol. 1 - Runkmirakel, porrkastruller och bärande betong",
        description:
          "OTROLIGT? Kanske. Men nu befinner sig Mia, Emma och Ina längst in i repetitionsgrottan och därmed har det blivit dags för avsnitt 1 (av 2) med the best of the very very best av Flashback Forevers drygt 100 publicerade avsnitt hittills. Ajjemän ni hör ju, det blir ett jävla röj.Vi ses på vägarna kära lyssnare! Biljetter tills showen hittar du på https://www.kahlo.se/flashbackforevershowenStörsta tacket till våra patroner på https://www.patreon.com/FlashbackForeverKlippt, musiklagt och mixat av https://aino.agency och Simon Hansson Get bonus content on Patreon Hosted on Acast. See acast.com/privacy for more information.",
        audioUrl:
          "https://sphinx.acast.com/p/open/s/60dc19b21f5e91001249f5e5/e/6213ef510c632e00129d6fa9/media.mp3?tk=eyJ0ayI6ImRlZmF1bHQiLCJhZHMiOnRydWUsInNwb25zIjp0cnVlLCJzdGF0dXMiOiJwdWJsaWMifQ==&sig=9UJ-Q52WGKIPF8oN2xgGTdObOQlWX2hvOYzds3ixATU",
        imageUrl:
          "https://assets.pippa.io/shows/60dc19b21f5e91001249f5e5/1631265565548-3feec9b7624c7e68058d150c5e596620.jpeg",
        podcastSeries: {
          uuid: "79d84a17-7d8d-44db-89db-6b5ac8e425a1",
          name: "Flashback Forever",
          imageUrl:
            "https://assets.pippa.io/shows/60dc19b21f5e91001249f5e5/1631265565548-3feec9b7624c7e68058d150c5e596620.jpeg",
        },
      },
      {
        uuid: "ed8d92e4-e289-4851-bb08-f8d0adec8e78",
        name: "#97 Best of the Very Very Best, vol. 2 - Pudelrock och personlig utveckling",
        description:
          "Det känns helt sjukt, men idag, 1 mars 2022, är det premiär för showen och därmed har det blivit dags för avsnitt 2 (av 2) med the best of the very very best av Flashback Forevers drygt 100 publicerade avsnitt hittills. Nu blir det ös!Vi ses på vägarna kära lyssnare! Biljetter tills showen hittar du på https://www.kahlo.se/flashbackforevershowenStörsta tacket till våra patroner på https://www.patreon.com/FlashbackForever och till alla som har tipsat om favoritavsnitt, bloopers och annat gött på Discord. Ni är bäst!Klippt, musiklagt och mixat av https://aino.agency och Simon Hansson Get bonus content on Patreon Hosted on Acast. See acast.com/privacy for more information.",
        audioUrl:
          "https://sphinx.acast.com/p/open/s/60dc19b21f5e91001249f5e5/e/621d15ef83ff8c00129dd711/media.mp3?tk=eyJ0ayI6ImRlZmF1bHQiLCJhZHMiOnRydWUsInNwb25zIjp0cnVlLCJzdGF0dXMiOiJwdWJsaWMifQ==&sig=oz-43ioVBJTuqkh0BlnlL8-sdEOCMPKmZyhIfqWvpwA",
        imageUrl:
          "https://assets.pippa.io/shows/60dc19b21f5e91001249f5e5/1631265565548-3feec9b7624c7e68058d150c5e596620.jpeg",
        podcastSeries: {
          uuid: "79d84a17-7d8d-44db-89db-6b5ac8e425a1",
          name: "Flashback Forever",
          imageUrl:
            "https://assets.pippa.io/shows/60dc19b21f5e91001249f5e5/1631265565548-3feec9b7624c7e68058d150c5e596620.jpeg",
        },
      },
      {
        uuid: "2ee3b2db-314e-49c4-92bd-3a9c6360163a",
        name: "#98 Hjälp! Polack fifflar med mitt internet!",
        description:
          "Här kommer för ovanlighetens skull ett helt jävla vanligt avsnitt av Flashback Forever! Ina har kollat närmare på vad flashbackarna tycker om Linkedin (spoiler: De hatar skiten ur det), Emma försöker reda i en härva med en internetfifflande polack och Mia har tittat på Flashbackarnas försök att stämma skiten ur storföretagen. Ja ni hör ju: Ett helt jävla vanligt avsnitt helt enkelt!Klippt och mixat av https://aino.agencyStörsta tacket till våra patroner på https://www.patreon.com/FlashbackForeverInas trådar:https://www.flashback.org/t3036101https://www.flashback.org/t3385068Emmas tråd:https://www.flashback.org/t1289258Mias trådar:https://www.flashback.org/t3314034https://www.flashback.org/t2957244https://www.flashback.org/t3254775https://www.flashback.org/t3327827https://www.flashback.org/t2489760https://www.flashback.org/t1306939https://www.flashback.org/t876440https://www.flashback.org/t2366503 Get bonus content on Patreon Hosted on Acast. See acast.com/privacy for more information.",
        audioUrl:
          "https://sphinx.acast.com/p/open/s/60dc19b21f5e91001249f5e5/e/62260743989de7001453eeda/media.mp3?tk=eyJ0ayI6ImRlZmF1bHQiLCJhZHMiOnRydWUsInNwb25zIjp0cnVlLCJzdGF0dXMiOiJwdWJsaWMifQ==&sig=laHAWa8TvkDHEvX4JBNKq-hh1MGuvOrRaZ29i0dvgEs",
        imageUrl:
          "https://assets.pippa.io/shows/60dc19b21f5e91001249f5e5/1631265565548-3feec9b7624c7e68058d150c5e596620.jpeg",
        podcastSeries: {
          uuid: "79d84a17-7d8d-44db-89db-6b5ac8e425a1",
          name: "Flashback Forever",
          imageUrl:
            "https://assets.pippa.io/shows/60dc19b21f5e91001249f5e5/1631265565548-3feec9b7624c7e68058d150c5e596620.jpeg",
        },
      },
    ],
  },
};
