import React from "react";

const Center = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto",
      }}
    >
      {props.children}
    </div>
  );
};

export default Center;
