import { styled } from "@stitches/react";

const ContentWrapper = styled("div", {
  width: "100%",
  backgroundColor: "white",
  paddingTop: "24px",
  paddingBottom: "24px",
});

export default ContentWrapper;
