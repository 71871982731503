import { Button } from "antd";

function LoginButton(props) {
  return (
    <Button type="link" size="large" style={{ color: "#064322" }} {...props}>
      Log in
    </Button>
  );
}

export default LoginButton;
