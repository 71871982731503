import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

const DB_URI =
  "https://castel-20bd6-default-rtdb.europe-west1.firebasedatabase.app";

const firebaseConfig = {
  apiKey: "AIzaSyD3JexQIHUH1YcNaw2sQ5ySL1ds5_MW0U0",
  authDomain: "castel-20bd6.firebaseapp.com",
  projectId: "castel-20bd6",
  storageBucket: "castel-20bd6.appspot.com",
  messagingSenderId: "663638326105",
  appId: "1:663638326105:web:5a74b8eeb370159bdbaa48",
  measurementId: "G-H1TMSPDFNY",
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const realtimeDb = getDatabase(app, DB_URI);
export const googleAuthProvider = new GoogleAuthProvider(app);
