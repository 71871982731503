import { List } from "antd";
import EpisodeTile from "../EpisodeTile/EpisodeTile";

const EpisodeList = ({ episodes }) => {
  return (
    <List
      dataSource={episodes}
      renderItem={(episode) => <EpisodeTile episode={episode} />}
    />
  );
};

export default EpisodeList;
