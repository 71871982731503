import Container from "../Container/Container";
import { Layout, Row, Typography } from "antd";

const { Title } = Typography;

function Header({ children }) {
  return (
    <Layout.Header
      style={{
        padding: "30px 0px",
        backgroundColor: "#EDE8D8",
      }}
    >
      <Container>
        <Row justify="space-between" align="middle">
          <Title
            level={1}
            style={{ color: "#064322", fontSize: 38, fontWeight: 300 }}
          >
            Castel
          </Title>
          {children}
        </Row>
      </Container>
    </Layout.Header>
  );
}

export default Header;
