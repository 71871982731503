import { Typography, Divider, Space } from "antd";

const { Title } = Typography;

const PodcastTile = ({ podcast }) => {
  return (
    <>
      <Space direction="vertical" size="middle">
        <Space direction="horizontal" size="middle" align="start">
          <Space direction="vertical" size="small">
            <Title
              level={4}
              style={{ margin: 0, fontWeight: 500 }}
              ellipsis={{ rows: 2 }}
            >
              <a href={podcast?.rssUrl}>{podcast?.name}</a>
            </Title>
          </Space>
        </Space>
      </Space>
      <Divider />
    </>
  );
};

export default PodcastTile;
