import React, { useEffect, useState } from "react";
import { ref, child, get } from "firebase/database";
import { realtimeDb } from "../../utils/firebase";
import PodcastTile from "../PodcastTile/PodcastTile";
import Center from "../Center/Center";
import CircularProgress from "@mui/material/CircularProgress";
import { List } from "antd";

const FirebaseError = {
  PermissionDenied: "Error: Permission denied",
};

function PodcastList({ user }) {
  const [podcasts, setPodcasts] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getPodcasts = async () => {
      const dbRef = ref(realtimeDb);
      // poscasts is correct... there is a spelling misstake in the db...
      await get(child(dbRef, "poscasts"))
        .then((snapshots) => {
          if (snapshots.exists()) {
            const list = snapshots.val();
            setPodcasts(list);
          } else {
            console.log("No data available");
          }
        })
        .then(() => setIsLoading(false))
        .catch((e) => {
          setError(e.toString());
        });
    };
    getPodcasts();
  }, []);

  return error === FirebaseError.PermissionDenied ? (
    <div>
      Hello {user?.displayName?.split(" ")[0] ?? "there"}! We are currently in
      Beta. To not exhaust our resources while developing, we only give access
      to those who kindly become beta testers. Please reach out if you are
      interested in getting access and helping out 😊🙏 - Emma
    </div>
  ) : isLoading ? (
    <Center>
      <CircularProgress color="inherit" />
    </Center>
  ) : (
    <List
      dataSource={podcasts}
      renderItem={(podcast) => <PodcastTile podcast={podcast} />}
    />
  );
}

export default PodcastList;
