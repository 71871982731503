import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { globalCss } from "@stitches/react";

const globalStyles = globalCss({
  "*": { fontFamily: "Outfit, sans-serif !important" },
  body: { margin: 0, backgroundColor: "#EDE8D8" },
  "h1, h2, h3, h4, h4, h6, p": {
    marginTop: "0 !important",
    marginBottom: "0 !important",
    fontWeight: "500",
    letterSpacing: "-0.4",
  },
});

globalStyles();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
