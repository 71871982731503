import { Typography, Image, Divider, Space } from "antd";
import Description from "../Description/Description";

const { Paragraph, Title } = Typography;

const EpisodeTile = ({ episode }) => {
  return (
    <>
      <Space direction="vertical" size="middle">
        <Space direction="horizontal" size="middle" align="start">
          <Image
            style={{ borderRadius: 8 }}
            width={88}
            height={88}
            alt="episode-cover"
            src={episode.imageUrl ?? episode.podcastSeries.imageUrl}
            fallback="https://i.imgur.com/Se0dZ5V.png"
          ></Image>
          <Space direction="vertical" size="small">
            <Paragraph
              type="secondary"
              ellipsis={{ rows: 2 }} // CANT MAKE IT JUST ONE ROW :(
              style={{ margin: 0 }}
            >
              {episode.podcastSeries.name}
            </Paragraph>
            <Title
              level={4}
              style={{ margin: 0, fontWeight: 500 }}
              ellipsis={{ rows: 2 }}
            >
              {episode.name}
            </Title>
          </Space>
        </Space>
        <Description style={{ margin: 0 }} description={episode.description} />
      </Space>
      <Divider />
    </>
  );
};

export default EpisodeTile;
