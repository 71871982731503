import { Typography } from "antd";

const { Paragraph } = Typography;

function Description({ description }) {
  return (
    <Paragraph
      style={{ margin: 0 }}
      ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
    >
      {description}
    </Paragraph>
  );
}

export default Description;
